<template>
  <div class="publish-box">
    <div class="publish-header">
      <div class="publish-header-content">
        <img src="@/static/img/laboratory/blacklogo.png"
             alt="logo"
             class="publish-header-content-l"
             @click="goSy" />
        <div class="publish-header-content-r">
          <div class="publish-header-content-r-menu">
            <div class="publish-header-content-r-menu-icon hover"
                 @click="$router.go(-1)">
              <i class="el-icon-back"></i>
            </div>
            <span style="margin-left:8px">推荐资料</span>
          </div>
          <div class="publish-header-content-r-btn hover"
               @click="tjInfo">
            推荐
          </div>
        </div>
      </div>
    </div>
    <div class="publish-content">
      <div class="title">
        资料完善度<span>{{ integrity }}%</span>(完善度100%更容易被联系哦)
      </div>
      <upImageFile style="margin-top:10px;"
                   ref="imageFile"
                   :fileList="imageList"
                   @setfileList="fileList => (this.imageList = fileList)"
                   :uidObject="imageUidObject"
                   @count="img_count => (this.img_count = img_count)"
                   :coverpath="coverpath"
                   @setcoverpath="coverpath => (this.coverpath = coverpath)" />
      <upVideoFile style="margin:10px 0;"
                   ref="videoFile"
                   :fileList="videoList"
                   @setfileList="fileList => (this.videoList = fileList)"
                   @count="video_count => (this.video_count = video_count)" />
      <div class="ddfiv">
        还可以上传{{ 6 - img_count }}张图片，{{ 3 - video_count }}条视频
      </div>
      <div class="formData">
        <div class="one">
          <p>姓名</p>
          <el-input placeholder="请输入主播真实姓名"
                    v-model="form.name"></el-input>
        </div>
        <div class="one">
          <p>意向平台</p>
          <el-select v-model="form.plate_forms"
                     multiple
                     placeholder="请选择意向平台">
            <el-option v-for="item in optionList"
                       :key="item.value"
                       :label="item.title"
                       :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="one">
          <p>直播经验</p>
          <el-input placeholder="请输入主播的直播经验"
                    v-model="form.broadcasting_month">
            <i slot="suffix"
               style="line-height:40px;">月</i>
          </el-input>
        </div>
        <div class="one">
          <p>微信号</p>
          <el-input placeholder="请填写主播可联系的微信号"
                    v-model="form.wechat"></el-input>
        </div>
        <div class="one">
          <p>手机号</p>
          <el-input placeholder="请填写主播可联系的手机号"
                    v-model="form.phone"></el-input>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import upImageFile from "./item/upImageFile";
import upVideoFile from "./item/upVideoFile";
export default {
  components: {
    upImageFile,
    upVideoFile
  },
  data () {
    return {
      optionList: [], //平台多选框
      form: {
        name: "",
        plate_forms: [],
        broadcasting_month: "",
        wechat: "",
        phone: ""
      },
      img_count: 0,
      video_count: 0,
      ffList: [], //回显数据列表
      imageList: [], //回显的图片列表
      imageUidObject: {},
      videoList: [], //回显的视频列表
      coverpath: "",
      broker_id: "",
      record_id: "" //编辑用
    };
  },

  created () {
    this.reqPlateForm();
    this.ffList = this.$route.params.item;
    console.log(this.ffList);
    if (typeof(this.ffList)!=='undefined') {
      this.form = {
        name: this.ffList.anchor_name,
        plate_forms: this.ffList.plate_forms,
        broadcasting_month: this.ffList.broadcasting_month,
        wechat: this.ffList.anchor_wechat,
        phone: this.ffList.anchor_phone
      };
      const imageList = [];
      this.ffList.anchor_medias.forEach((item, index) => {
        if (item.type == "image") {
          imageList.push({
            url: item.src,
            uid: index
          });
          if (item.is_cover) this.coverpath = item.src;
        }
      });
      // this.imageUidObject = imageUidObject
      this.imageList = imageList;
      const videoList = [];
      this.ffList.anchor_medias.forEach((item, index) => {
        if (item.type == "video") {
          console.log(item)
          videoList.push({
            url: item.src,
            uid: index
          });
        }
      });
      this.videoList = videoList
      this.broker_id = this.ffList.uuid;
    } else {
      this.broker_id = this.$route.query.broker_id;
    }
  },
  methods: {
    goSy () {
      this.$router.push('/homepage')
    },
    async reqPlateForm (params = {}) {
      const { data, status } = await this.$api.xtPlateForm(params);
      if (status == 200) this.optionList = data;
    },
    getMedias () {
      const { imageFile, videoFile } = this.$refs;
      const medias = [];
      this.imageList.forEach(item => {
        if (item.url.indexOf("https://oss.mcn-open.com/") == -1) {
          medias.push({
            address: imageFile.uidObject[item.uid].path,
            type: "image",
            is_cover: item.url == this.coverpath
          });
        } else {
          // console.log(item.url.split('/').slice(3).join('/'))
          medias.push({
            address: item.url
              .split("/")
              .slice(3)
              .join("/"),
            type: "image",
            is_cover: item.url == this.coverpath
          });
        }
      });

      this.videoList.forEach(item => {
        if (item.url.indexOf("https://oss.mcn-open.com/") == -1) {
          medias.push({
            address: videoFile.uidObject[item.uid].path,
            type: "video",
            is_cover: 0
          });
        } else {
          // console.log(item.url.split('/').slice(3).join('/'))
          medias.push({
            address: item.url
              .split("/")
              .slice(3)
              .join("/"),
            type: "video",
            is_cover: 0
          });
        }
      });
      console.log(medias);
      return medias;
    },
    async tjInfo () {
      const medias = this.getMedias(); //图像和视频
      const complete_ratio = this.integrity; //完整度
      if (typeof(this.ffList)=='undefined') {
        console.log(complete_ratio);
        if (this.img_count == 0) this.$message("至少上传一张图片");
        const { status, info } = await this.$api.tjInfo({
          ...this.form,
          medias,
          broker_id: this.broker_id,
          complete_ratio
        });
        if (status == 200) {
          this.$message(info);
          this.$router.go(-1);
        } else {
          this.$message.error(info);
        }
        console.log(status, info);
      } else {
        //编辑资料
        const { status, info } = await this.$api.editXtInfo({
          ...this.form,
          medias,
          broker_id: this.broker_id,
          complete_ratio
        });
        if (status == 200) {
          this.$message(info);
          this.$router.go(-1);
        } else {
          this.$message.error(info);
        }
      }
    }
  },
  computed: {
    // 完整度
    integrity () {
      let sum = 0;
      Object.keys(this.form).map(item => {
        if (this.form[item]) {
          if (item == "plate_forms") {
            if (this.form.plate_forms.length) {
              sum++;
            }
          } else {
            sum++;
          }
        }
      });
      sum = sum + this.img_count + this.video_count;
      return ((100 / 14) * sum).toFixed(2);
    }
  }
};
</script>
<style lang="scss" scoped>
/deep/ .el-select > .el-input {
  display: block;
  width: 184%;
}
/deep/ .el-select__tags {
  width: 200%;
}
.logo {
  width: 156px;
}
.publish-box {
  background: #f7f7f7;
  min-height: 100vh;
}
.publish {
  &-header {
    height: 60px;
    background: #fff;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.06);
    &-content {
      width: 880px;
      margin: 0 auto;
      height: 100%;
      position: relative;
      &-l {
        width: 156px;
        height: 42px;
        position: absolute;
        left: -197px;
        top: 0px;
        bottom: 0px;
        margin: auto;
      }
      &-r {
        width: 880px;
        margin: 0 auto;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        &-menu {
          display: flex;
          align-items: center;
          &-icon {
            width: 24px;
            height: 24px;
            background: #f7f7f7;
            border: 1px solid #e3e3e3;
            border-radius: 12px;
            text-align: center;
            line-height: 24px;
            color: #999;
          }
          &-text {
            line-height: 24px;
            margin-left: 20px;
            font-size: 16px;
            color: #333333;
          }
        }
        &-btn {
          width: 82px;
          line-height: 32px;
          background: #df2a29;
          border-radius: 2px;
          text-align: center;
          color: #fff;
        }
      }
    }
  }
  &-content {
    width: 880px;
    min-height: 500px;
    margin: 0 auto;
    margin-top: 20px;
    background: #fff;
    padding-left: 30px;
    padding-top: 30px;
    .title {
      height: 22px;
      font-size: 16px;
      color: #2990f9;
      line-height: 22px;
      span {
        color: #df2a29;
      }
    }
    .formData {
      display: flex;
      flex-wrap: wrap;
      margin-top: 30px;
      .one {
        width: 44%;
        margin-right: 40px;
        margin-bottom: 20px;
        p {
          height: 20px;
          font-size: 14px;
          color: #333333;
          line-height: 20px;
          margin-bottom: 8px;
        }
      }
    }
  }
}
/deep/ .el-dialog {
  background: black;
}
.ddfiv {
  margin: 6px 0 18px 0;
  color: #999;
  font-size: 12px;
}
</style>